import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import moment from "moment-timezone";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import classNames from "classnames";
import {
  setCurrentPage,
  getHomeAcquisitionData,
  getPhotoShootDetailsData,
} from "actions";
import * as C from "components";
import * as H from "helper";
import * as V from "styles/variables";
import PhotoShoot from "components/PicTree/Home/PhotoShoot";
import PhotoShootMap from "components/PicTree/Game/Map/PhotoShootMap";

const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeReviewDetails = ({
  getHomeAcquisitionData,
  pageTopLoader,
  dateRange,
  kpiSalesData,
  setCurrentPage,
  getPhotoShootDetailsData,
  dataPhotoShootDetails,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getPhotoShootDetailsData();
  }, []);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée TEPCO</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">通常モード</Typography>
              <Typography variant="body1">詳細</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree" />
          </Grid>
        </Grid>

        {/* <Grid container spacing={3} style={{ marginTop: -8, marginBottom: 6 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <PhotoShoot />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}

        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12}>
            {dataPhotoShootDetails.length > 0 &&
              <PhotoShootMap records={dataPhotoShootDetails}  width="100%" height="70vh" />
            }
          </Grid>
        </Grid>

        {/* <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            {pageTopLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              kpiSalesData.length > 0 ? (
                null
              ) : (
                <div className={classes.selectGameTxt}>
                  <Chip
                    label="TABLE DATA"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : null}
          </Grid>
        </Grid> */}
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeReviewDetails.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRange: state.page.dateRange,
  kpiSalesData: state.kpi.kpiSalesData,
  kpiSalesDataSizeSelected: state.kpi.kpiSalesDataSizeSelected,
  dataPhotoShootDetails: state.pictree.dataPhotoShootDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomeAcquisitionData: () => dispatch(getHomeAcquisitionData()),
  getPhotoShootDetailsData: () => dispatch(getPhotoShootDetailsData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeReviewDetails));

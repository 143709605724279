// Icons Material can be defined here, not fontAwesome
// AK: N9SuvYfjxFcxiVumz4zo4O1sws4h
import {
  PicTreeMapNtt,
} from "pages";

export default {
  items: [
    {
      path: "/game/pictree/ntt/photos/map",
      name: "地図表示",
      type: "link",
      icon: false,
      component: PicTreeMapNtt,
      sideBar: true,
      divider: false,
    },
  ],
};

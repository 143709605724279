import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getPhotoShootMapDataNtt,
  setPictreeMapType,
} from "actions";
import * as C from "components";
import PhotoShootMapNtt from "components/PicTree/Game/Map/PhotoShootMapNtt";
import SingleSelect from "components/Select/SingleSelect";
import CalendarRangePicTreeNtt from "components/Calendar/CalendarRangePicTreeNtt";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
  })
);

const PicTreeMapNtt = ({
  currentPage,
  setCurrentPage,
  pageTopLoader,
  photoShootMapData,
  getPhotoShootMapDataNtt,
  setPictreeMapType,
  dateRangePicTreeNtt,
  selectedSeasonNtt,
}) => {
  const classes = useStyles();
  const [chartSize, setChartSize] = useState({ name: "撮影ずみ", value: "taken" });
  const [photoType, setPhotoType] = useState("taken");
  const [initialDataCall, setInitialDataCall] = useState(true);
  const [singleSelectDisabled, setSingleSelectDisabled] = useState(false);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  useEffect(() => {
    if (
      dateRangePicTreeNtt[0] !== null &&
      dateRangePicTreeNtt[1] !== null &&
      initialDataCall
    ) {
      getPhotoShootMapDataNtt({type: "denchu", filter: "taken"});
      setInitialDataCall(false);
    }
  }, [dateRangePicTreeNtt]);

  const handleChange = (event) => {
    setChartSize(event.target);
    setPictreeMapType(event.target.value);
    setPhotoType(event.target.value);
    getPhotoShootMapDataNtt({type: "denchu", filter: event.target.value});
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée NTT</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">地図表示</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <CalendarRangePicTreeNtt
              mDate={"2024/12/7"}
              page="pictree-map"
              singlePeriod
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 8, marginBottom: 8 }}
          justifyContent="flex-start"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 8, }}>
            <SingleSelect
              selected={chartSize}
              options={[
                { name: `撮影済み`, value: "taken" },
                { name: `未撮影`, value: "untaken" },
              ]}
              handleChange={handleChange}
              disabled={singleSelectDisabled}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" style={{marginTop: 10, marginLeft: 16}}>
              {photoType === "taken" ? "撮影数：" : "未撮影数："}
              {photoShootMapData.length > 0 && !pageTopLoader ? photoShootMapData.length : "--"}
            </Typography>
          </Grid>
        </Grid>

        {photoShootMapData.length > 0 &&
          <PhotoShootMapNtt
            records={photoShootMapData}
            type="photoshoot"
            width="100%"
            height="85vh"
            status={chartSize === undefined ? "taken" : chartSize.value}
            seasonId={
              selectedSeasonNtt.length > 0
                ? selectedSeasonNtt[0]["seasonId"]
                : false
            }
          />
        }
        {photoShootMapData.length == 0 &&
          <PhotoShootMapNtt
            records={[]}
            type="photoshoot"
            width="100%"
            height="85vh"
            status="taken"
            seasonId={
              selectedSeasonNtt.length > 0
                ? selectedSeasonNtt[0]["seasonId"]
                : false
            }
          />
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeMapNtt.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentPage: state.page.currentPage,
  pageTopLoader: state.page.pageTopLoader,
  photoShootMapData: state.pictreeNtt.photoShootMapData,
  dateRangePicTreeNtt: state.page.dateRangePicTreeNtt,
  selectedSeasonNtt: state.pictreeNtt.selectedSeason,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPhotoShootMapDataNtt: (param) => dispatch(getPhotoShootMapDataNtt(param)),
  setPictreeMapType: (param) => dispatch(setPictreeMapType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeMapNtt));

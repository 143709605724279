import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  setPictreeView,
  getPhotoShootMapData,
  setPictreeMapType,
} from "actions";
import * as C from "components";
import PhotoShootMap from "components/PicTree/Game/Map/PhotoShootMap";
import SingleSelect from "components/Select/SingleSelect";
import CalendarRangePicker from 'components/Calendar/CalendarRangePicker';

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeMap = ({
  setCurrentPage,
  pageTopLoader,
  setPictreeView,
  photoShootMapData,
  getPhotoShootMapData,
  setPictreeMapType,
  dateRangePicTree,
}) => {
  const classes = useStyles();
  const [chartSize, setChartSize] = useState({ name: "撮影ずみ", value: "taken" });
  const [photoType, setPhotoType] = useState("taken");
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setPictreeView("photo/map");
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getPhotoShootMapData({type: "denchu", filter: "taken"});
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  const handleChange = (event) => {
    setChartSize(event.target.value);
    setPictreeMapType(event.target.value);
    setPhotoType(event.target.value);
    getPhotoShootMapData({type: "denchu", filter: event.target.value});
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée TEPCO</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">通常モード</Typography>
              <Typography variant="body1">地図表示</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <CalendarRangePicker mDate={"2024/04/13"} page="pictree-map" pictree />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 8, marginBottom: 8 }}
          justifyContent="flex-start"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 8, }}>
          <SingleSelect
            selected={chartSize}
            options={[
              { name: `撮影済み`, value: "taken" },
              { name: `未撮影`, value: "untaken" },
            ]}
            handleChange={handleChange}
          />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" style={{marginTop: 10, marginLeft: 16}}>
              {photoType === "taken" ? "撮影数：" : "未撮影数："}
              {photoShootMapData.length > 0 && !pageTopLoader ? photoShootMapData.length : "--"}
            </Typography>
          </Grid>
        </Grid>

        {photoShootMapData.length > 0 &&
          <PhotoShootMap records={photoShootMapData} type="photoshoot"  width="100%" height="85vh" />
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeMap.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRangePicTree: state.page.dateRangePicTree,
  photoShootMapData: state.pictree.photoShootMapData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
  getPhotoShootMapData: (param) => dispatch(getPhotoShootMapData(param)),
  setPictreeMapType: (param) => dispatch(setPictreeMapType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeMap));

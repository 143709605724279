import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Checkbox,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment-timezone";
import { setDateRangePicTree, setDateRangePicTreeMinMax, setSelectedSeason, getSeasonData } from "actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: "0.75rem",
      height: 24,
    },
    autocomplete: {
      width: 490,
      marginTop: -8,
    },
    seasonTextField: {
      "& .MuiOutlinedInput-root": {
        padding: "1px 8px",
      },
      "& .MuiOutlinedInput-input": {
        height: "1.2em",
      },
    },
  })
);

const Period = ({
  setDateRangePicTree,
  setDateRangePicTreeMinMax,
  setSelectedSeason,
  getSeasonData,
  seasonData,
  selectedSeason,
  dateRangePicTree,
  singelSelect,
}) => {
  const classes = useStyles();
  const currentDateTime = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
  const today = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
  const todayFormatted = moment(today).format("YYYY-MM-DD");

  useEffect(() => {
    getSeasonData();
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      const matchedSeason = seasonData.find((season) => {
        const startDate = season.seasonStart;
        const endDate = season.seasonEnd;
        return today >= startDate && today <= endDate;
      });

      if (!selectedSeason || selectedSeason.length === 0) {
        if (matchedSeason) {
          setSelectedSeason([matchedSeason]);
          const defaultMaxDateFormatted = moment(matchedSeason.seasonEnd).format('YYYY-MM-DD');
          const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);
          if (isEndDateAfterToday) {
            setDateRangePicTreeMinMax([
              matchedSeason.seasonStart.split(" ")[0],
              todayFormatted,
            ]);
            setDateRangePicTree([
              matchedSeason.seasonStart.split(" ")[0],
              todayFormatted,
            ]);
          } else {
            setDateRangePicTreeMinMax([
              matchedSeason.seasonStart.split(" ")[0],
              matchedSeason.seasonEnd.split(" ")[0],
            ]);
            setDateRangePicTree([
              matchedSeason.seasonStart.split(" ")[0],
              matchedSeason.seasonEnd.split(" ")[0],
            ]);
          }

        } else {
          setSelectedSeason([seasonData[0]]);
        }
      }
    }
  }, [seasonData]);

  // dateRangePicTreeの変更を監視し、シーズンを選択
  useEffect(() => {
    if (dateRangePicTree.length === 2 && seasonData.length > 0) {
      const [rangeStart, rangeEnd] = dateRangePicTree.map((date) =>
        moment(date, "YYYY-MM-DD")
      );

      // 範囲内のシーズンをフィルタリング
      const matchingSeasons = seasonData.filter((season) => {
        const seasonStart = moment(season.seasonStart, "YYYY-MM-DD HH:mm");
        const seasonEnd = moment(season.seasonEnd, "YYYY-MM-DD HH:mm");
        return (
          seasonEnd.isSameOrAfter(rangeStart) &&
          seasonStart.isSameOrBefore(rangeEnd)
        );
      });

      // Reduxの `selectedSeason` を更新
      setSelectedSeason(matchingSeasons);
    }
  }, [dateRangePicTree, seasonData]);

  const handleSelectionChange = (event, value) => {
    const uniqueValue = value.filter((option, index, self) => {
      const isDuplicate =
        self.filter((item) => item.seasonId === option.seasonId).length > 1;
      return !isDuplicate;
    });

    const validSelections = uniqueValue.filter(
      (option, index, self) =>
        self.findIndex((o) => o.seasonId === option.seasonId) === index
    );

    if (validSelections.length > 0) {
      const selectedSeasonsData = seasonData.filter((s) =>
        validSelections.some((item) => item.seasonId === s.seasonId)
      );

      const earliestStart = selectedSeasonsData
        .map((s) => s.seasonStart)
        .reduce((a, b) => (a < b ? a : b));
      const latestEnd = selectedSeasonsData
        .map((s) => s.seasonEnd)
        .reduce((a, b) => (a > b ? a : b));

      // check end date if before today
      const defaultMaxDateFormatted = moment(latestEnd).format('YYYY-MM-DD');
      const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);

      if (isEndDateAfterToday) {
        setDateRangePicTreeMinMax([
          earliestStart.split(" ")[0],
          todayFormatted,
        ]);
        setDateRangePicTree([
          earliestStart.split(" ")[0],
          todayFormatted,
        ]);
      } else {
        setDateRangePicTreeMinMax([
          earliestStart.split(" ")[0],
          latestEnd.split(" ")[0],
        ]);
        setDateRangePicTree([
          earliestStart.split(" ")[0],
          latestEnd.split(" ")[0],
        ]);
      }

      // 範囲内のシーズンを取得
      const autoSelectedSeasons = seasonData.filter(
        (s) =>
          moment(s.seasonStart).isSameOrAfter(earliestStart) &&
          moment(s.seasonEnd).isSameOrBefore(latestEnd)
      );

      // 現在の選択に追加
      const updatedSelections = [...validSelections, ...autoSelectedSeasons].filter(
        (option, index, self) =>
          self.findIndex((o) => o.seasonId === option.seasonId) === index
      );

      // ステートを更新
      setSelectedSeason(updatedSelections);
    } else {
      setDateRangePicTreeMinMax([]);
      setDateRangePicTree([]);
    }
  };

  const handleSignleSeasonChange = (event, value) => {
    const selectedSeasonsData = [value];
    const earliestStart = selectedSeasonsData
    .map((s) => s.seasonStart)
    .reduce((a, b) => (a < b ? a : b));
    const latestEnd = selectedSeasonsData
      .map((s) => s.seasonEnd)
      .reduce((a, b) => (a > b ? a : b));

    // check end date if before today
    const defaultMaxDateFormatted = moment(latestEnd).format('YYYY-MM-DD');
    const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);

    if (isEndDateAfterToday) {
      setDateRangePicTreeMinMax([
        earliestStart.split(" ")[0],
        todayFormatted,
      ]);
      setDateRangePicTree([
        earliestStart.split(" ")[0],
        todayFormatted,
      ]);
    } else {
      setDateRangePicTreeMinMax([
        earliestStart.split(" ")[0],
        latestEnd.split(" ")[0],
      ]);
      setDateRangePicTree([
        earliestStart.split(" ")[0],
        latestEnd.split(" ")[0],
      ]);
    }
    setSelectedSeason(selectedSeasonsData);
  }

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <Grid container spacing={1} direction="row">
          <Grid item spacing={1}>
            シーズン：
          </Grid>
          <Grid item>
            <Autocomplete
              multiple={!singelSelect}
              id="season-autocomplete"
              options={seasonData}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                singelSelect && selectedSeason.length > 0 && selectedSeason[0].seasonName
                ? selectedSeason[0].seasonName === undefined
                  ? ""
                  : `${selectedSeason[0].seasonName} : ${selectedSeason[0].seasonStart} ~ ${selectedSeason[0].seasonEnd}`
                : option.seasonName === undefined
                  ? ""
                  : `${option.seasonName} : ${option.seasonStart} ~ ${option.seasonEnd}`
              }
              renderOption={(option, { selected }) => {
                const isFutureSeason = moment(option.seasonStart).isAfter(currentDateTime);
                const isSelected = selectedSeason.some((selected) => selected.seasonId === option.seasonId);
                return (
                  <div
                    style={{
                      // opacity: isFutureSeason ? 0.5 : 1,
                      // pointerEvents: isFutureSeason ? "none" : "auto",
                    }}
                  >
                    {!singelSelect &&
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={isSelected}
                        // disabled={false}
                      />
                    }
                    {`${option.seasonName}: `}
                    <span
                      style={{ color: "gray", marginLeft: 16, fontSize: 13 }}
                    >
                      {`${option.seasonStart} ~ ${option.seasonEnd}`}
                    </span>
                  </div>
                );
              }}
              value={selectedSeason}
              onChange={singelSelect ? handleSignleSeasonChange : handleSelectionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder=""
                  className={classes.seasonTextField}
                />
              )}
              renderTags={(selected, getTagProps) => {
                const visibleTags = selected.slice(0, 3);
                const extraCount = selected.length - visibleTags.length;
                return [
                  ...visibleTags.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.seasonName}
                      size="small"
                      className={classes.chip}
                    />
                  )),
                  extraCount > 0 && (
                    <Typography
                      key="extra"
                      variant="body2"
                      style={{ marginLeft: 8, color: "gray" }}
                    >
                      +{extraCount}
                    </Typography>
                  ),
                ];
              }}
              disableClearable
              className={classes.autocomplete}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Period.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  periodType: state.pictree.periodType,
  seasonData: state.pictree.seasonData,
  selectedSeason: state.pictree.selectedSeason,
  dateRangePicTree: state.page.dateRangePicTree,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTree: (param) => dispatch(setDateRangePicTree(param)),
  setDateRangePicTreeMinMax: (param) => dispatch(setDateRangePicTreeMinMax(param)),
  getSeasonData: () => dispatch(getSeasonData()),
  setSelectedSeason: (param) => dispatch(setSelectedSeason(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Period));

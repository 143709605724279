import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import {
  withStyles,
  makeStyles,
  createStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as Var from "styles/variables";
import {
  setDateRangePicTreeNtt,
  getHomePictreeCheckInNtt,
  getHomePictreePhotoShootNtt,
  getPictreeDataCheckInTrendNtt,
  getPictreeDataPhotoShootTrendNtt,
  getPictreeDataPhotoDenchuKaisuTrend,
  getInGameItemSalesByOccurrence,
  getPhotoShootMapDataNtt,
} from "actions";
import PeriodNtt from "components/PicTree/Filter/PeriodNtt";

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
      flexGrow: 1,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    container: {
      display: "flex",
      width: 300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignItems: "baseline",
    },
    delimiter: {
      paddingTop: theme.spacing(1),
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      color: Var.btnLabelColor,
    },
    grow: {
      flexGrow: 1,
    },
    sectionCalendar: {
      display: "flex",
      marginRight: theme.spacing(1),
    },
    picTreePredefined: {
      marginRight: theme.spacing(2),
    }
  })
);

const CalendarRangePicker = ({
  page,
  singlePeriod,
  dateRangePicTreeNtt,
  dateRangePicTreeMinMax,
  currentPage,
  getHomePictreeCheckInNtt,
  getHomePictreePhotoShootNtt,
  getPictreeDataCheckInTrendNtt,
  getPictreeDataPhotoShootTrendNtt,
  pictreeChartSize,
  getPhotoShootMapDataNtt,
  pictreeMapType,
  setDateRangePicTreeNtt,
}) => {
  const classes = useStyles();
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [error, setError] = useState([null, null]);
  const [minDate, setMinDate] = useState(moment("2024-12-07").tz("Asia/Tokyo").format("YYYY/MM/DD"));
  const [maxDate, setMaxDate] = useState(moment("2025-01-12").tz("Asia/Tokyo").format("YYYY/MM/DD"));

  const today = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
  const todayFormatted = moment(today).format("YYYY-MM-DD");

  useEffect(() => {
    const currentJstDate = moment().tz("Asia/Tokyo").format("YYYY/MM/DD");
    handleHomeCalendar(currentJstDate);
  }, []);

  useEffect(() => {
    if (dateRangePicTreeMinMax.length > 0) {
      const defaultMaxDateFormatted = moment(dateRangePicTreeMinMax[1]).format('YYYY-MM-DD');
      const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);
      setMinDate(dateRangePicTreeMinMax[0]);
      setMaxDate(isEndDateAfterToday ? todayFormatted : dateRangePicTreeMinMax[1]);
    }
  }, [dateRangePicTreeMinMax]);

  useEffect(() => {
    const defaultMaxDateFormatted = moment(dateRangePicTreeMinMax[1]).format('YYYY-MM-DD');
    const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);
    const dateRangeValue = isEndDateAfterToday ? [dateRangePicTreeNtt[0], todayFormatted] : dateRangePicTreeNtt;
    setDateRangeValue(dateRangeValue);
  }, [dateRangePicTreeNtt]);

  const handleHomeCalendar = (currentJstDate) => {
    setDateRangeValue(dateRangePicTreeNtt);
  };

  const setRange = (newValue) => {
    const startDate = moment(newValue[0]).format("YYYY-MM-DD");
    const endDate = moment(newValue[1]).format("YYYY-MM-DD");
    setDateRangePicTreeNtt([startDate, endDate]);
    setDateRangeValue([startDate, endDate]);
  };

  const fetchData = () => {
    if (currentPage === "/game/pictree/ntt/checkin") {
      getHomePictreeCheckInNtt();
      getPictreeDataCheckInTrendNtt(pictreeChartSize);
    } else if (currentPage === "/game/pictree/ntt/photos/overview") {
      getHomePictreePhotoShootNtt();
      getPictreeDataPhotoShootTrendNtt(pictreeChartSize);
    } else if (currentPage === "/game/pictree/ntt/photos/map") {
      getPhotoShootMapDataNtt({ type: "denchu", filter: pictreeMapType });
    }
  };

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <div className={classes.sectionCalendar}>
          <div className={classes.picTreePredefined}>
            <PeriodNtt
              // singlePeriod={currentPage.includes("photos/map") ? true : false}
              singlePeriod
            />
          </div>
          <DateRangePicker
            startText="Start"
            endText="End"
            disableHighlightToday={true}
            showToolbar={false}
            allowSameDateSelection={true}
            minDate={minDate}
            maxDate={maxDate}
            value={dateRangeValue}
            onChange={(newValue) => setRange(newValue)}
            onError={([startReason, endReason], [start, end]) => {
              if (startReason === "invalidRange") {
                setError([null, null]);
                return;
              }
              setError([startReason, endReason]);
            }}
            renderInput={(startProps, endProps) => (
              <div className={classes.container}>
                <TextField
                  id="standard-basic"
                  {...startProps}
                  error={Boolean(error[0])}
                  size="small"
                  disabled={page === "pictree-map" ? true : false}
                  helperText=""
                />
                <DateRangeDelimiter>
                  <span className={classes.delimiter}>~</span>
                </DateRangeDelimiter>
                <TextField
                  id="standard-basic"
                  {...endProps}
                  error={Boolean(error[0])}
                  size="small"
                  disabled={page === "pictree-map" ? true : false}
                  helperText=""
                />
              </div>
            )}
          />
          <Button
            // disabled={!btnStateDataFetch}
            className={classes.dataBtn}
            variant="contained"
            color="primary"
            onClick={() => fetchData()}
          >
            GO
          </Button>
        </div>
      </div>
    </div>
  );
};

CalendarRangePicker.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  btnStateDataFetch: state.page.btnStateDataFetch,
  dateRangePicTreeNtt: state.page.dateRangePicTreeNtt,
  dateRangePicTreeMinMax: state.page.dateRangePicTreeMinMax,
  currentPage: state.page.currentPage,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartSize,
  pictreeMapType: state.pictree.mapType,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTreeNtt: (param) => dispatch(setDateRangePicTreeNtt(param)),
  getHomePictreeCheckInNtt: (param) => dispatch(getHomePictreeCheckInNtt(param)),
  getHomePictreePhotoShootNtt: (param) => dispatch(getHomePictreePhotoShootNtt(param)),
  getPictreeDataCheckInTrendNtt: (param) => dispatch(getPictreeDataCheckInTrendNtt(param)),
  getPictreeDataPhotoShootTrendNtt: (param) => dispatch(getPictreeDataPhotoShootTrendNtt(param)),
  getPictreeDataPhotoDenchuKaisuTrend: (param) => dispatch(getPictreeDataPhotoDenchuKaisuTrend(param)),
  getInGameItemSalesByOccurrence: (param) => dispatch(getInGameItemSalesByOccurrence(param)),
  getPhotoShootMapDataNtt: (param) => dispatch(getPhotoShootMapDataNtt(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CalendarRangePicker));

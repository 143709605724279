import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Checkbox,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment-timezone";
import SingleSelect from "components/Select/SingleSelect";
import { setDateRangePicTreeNtt, setDateRangePicTreeMinMax, setSelectedSeasonNtt, getSeasonDataNtt } from "actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: "0.75rem",
      height: 24,
    },
    autocomplete: {
      width: 510,
      marginTop: -8,
    },
    seasonTextField: {
      "& .MuiOutlinedInput-root": {
        padding: "1px 8px",
      },
      "& .MuiOutlinedInput-input": {
        height: "1.2em",
      },
    },
  })
);

const PeriodNtt = ({
  setDateRangePicTreeNtt,
  setDateRangePicTreeMinMax,
  setSelectedSeasonNtt,
  getSeasonDataNtt,
  seasonData,
  selectedSeason,
  dateRangePicTreeNtt,
  singlePeriod,
}) => {
  const classes = useStyles();
  const currentDateTime = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
  const today = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
  const todayFormatted = moment(today).format("YYYY-MM-DD");

  const [seasonOption, setSeasonOption] = useState(
    { name: "北関東シーズン", value: "cm64mqm94000br3n0esu8c0n4" },
    { name: "秋田県北 & 県央・県南シーズン", value: "cm46zidp30003bpp6cxq101mo" },
  );

  useEffect(() => {
    getSeasonDataNtt();
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      const matchedSeasons = seasonData.filter((season) => {
        const startDate = season.seasonStart;
        const endDate = season.seasonEnd;
        return today >= startDate && today <= endDate;
      });

      if (!selectedSeason || selectedSeason.length === 0) {
        if (matchedSeasons.length > 0) {
          setSelectedSeasonNtt(matchedSeasons);
          const earliestStart = matchedSeasons
            .map((s) => s.seasonStart)
            .reduce((a, b) => (a < b ? a : b));
          const latestEnd = matchedSeasons
            .map((s) => s.seasonEnd)
            .reduce((a, b) => (a > b ? a : b));

          // check end date if before today
          const defaultMaxDateFormatted = moment(latestEnd).format('YYYY-MM-DD');
          const isEndDateAfterToday = moment(today).isBefore(defaultMaxDateFormatted);
          if (isEndDateAfterToday) {
            setDateRangePicTreeMinMax([
              earliestStart.split(" ")[0],
              todayFormatted,
            ]);
            setDateRangePicTreeNtt([
              earliestStart.split(" ")[0],
              todayFormatted,
            ]);
          } else {
            setDateRangePicTreeMinMax([
              earliestStart.split(" ")[0],
              latestEnd.split(" ")[0],
            ]);
            setDateRangePicTreeNtt([
              earliestStart.split(" ")[0],
              latestEnd.split(" ")[0],
            ]);
          }

          // setDateRangePicTreeMinMax([
          //   earliestStart.split(" ")[0],
          //   latestEnd.split(" ")[0],
          // ]);
          // setDateRangePicTreeNtt([
          //   earliestStart.split(" ")[0],
          //   latestEnd.split(" ")[0],
          // ]);
        } else {
          setSelectedSeasonNtt([seasonData[1]]);
          setDateRangePicTreeNtt([
            seasonData[0]["seasonStart"].split(" ")[0],
            seasonData[0]["seasonEnd"].split(" ")[0],
          ]);
        }
      }
    }
  }, [seasonData]);

  // dateRangePicTreeNttの変更を監視し、シーズンを選択
  useEffect(() => {
    if (dateRangePicTreeNtt.length === 2 && seasonData.length > 0) {
      const [rangeStart, rangeEnd] = dateRangePicTreeNtt.map((date) =>
        moment(date, "YYYY-MM-DD")
      );

      // 範囲内のシーズンをフィルタリング
      const matchingSeasons = seasonData.filter((season) => {
        const seasonStart = moment(season.seasonStart, "YYYY-MM-DD HH:mm");
        const seasonEnd = moment(season.seasonEnd, "YYYY-MM-DD HH:mm");
        return (
          seasonEnd.isSameOrAfter(rangeStart) &&
          seasonStart.isSameOrBefore(rangeEnd)
        );
      });

      // Reduxの `selectedSeason` を更新
      setSelectedSeasonNtt(matchingSeasons);
    }
  }, [dateRangePicTreeNtt, seasonData]);

  const handleSelectionChange = (event, value) => {
    const uniqueValue = value.filter((option, index, self) => {
      const isDuplicate =
        self.filter((item) => item.seasonId === option.seasonId).length > 1;
      return !isDuplicate;
    });

    const validSelections = uniqueValue.filter(
      (option, index, self) =>
        // !moment(option.seasonStart).isAfter(currentDateTime) &&
        self.findIndex((o) => o.seasonId === option.seasonId) === index
    );

    if (validSelections.length > 0) {
      const selectedSeasonsData = seasonData.filter((s) =>
        validSelections.some((item) => item.seasonId === s.seasonId)
      );

      const earliestStart = selectedSeasonsData
        .map((s) => s.seasonStart)
        .reduce((a, b) => (a < b ? a : b));

      const latestEnd = selectedSeasonsData
        .map((s) => s.seasonEnd)
        .reduce((a, b) => (a > b ? a : b));

      setDateRangePicTreeMinMax([
        earliestStart.split(" ")[0],
        latestEnd.split(" ")[0],
      ]);
      setDateRangePicTreeNtt([
        earliestStart.split(" ")[0],
        latestEnd.split(" ")[0],
      ]);

      // 範囲内のシーズンを取得
      const autoSelectedSeasons = seasonData.filter(
        (s) =>
          moment(s.seasonStart).isSameOrAfter(earliestStart) &&
          moment(s.seasonEnd).isSameOrBefore(latestEnd)
      );

      // 現在の選択に追加
      const updatedSelections = [...validSelections, ...autoSelectedSeasons].filter(
        (option, index, self) =>
          self.findIndex((o) => o.seasonId === option.seasonId) === index
      );

      // ステートを更新
      setSelectedSeasonNtt(validSelections);
    } else {
      setDateRangePicTreeMinMax([]);
      setDateRangePicTreeNtt([]);
    }
  };

  const handleChange = (event) => {
    setSeasonOption(event.target);
    if (event.target.value === "cm46zidp30003bpp6cxq101mo") {
      setSelectedSeasonNtt([seasonData[1]]);
      setDateRangePicTreeNtt([
        seasonData[1]["seasonStart"].split(" ")[0],
        seasonData[1]["seasonEnd"].split(" ")[0],
      ]);
      setDateRangePicTreeMinMax([
        seasonData[1]["seasonStart"].split(" ")[0],
        seasonData[1]["seasonEnd"].split(" ")[0],
      ]);
    } else {
      setSelectedSeasonNtt([seasonData[0]]);
      setDateRangePicTreeNtt([
        seasonData[0]["seasonStart"].split(" ")[0],
        seasonData[0]["seasonEnd"].split(" ")[0],
      ]);
      setDateRangePicTreeMinMax([
        seasonData[0]["seasonStart"].split(" ")[0],
        seasonData[0]["seasonEnd"].split(" ")[0],
      ]);
    }
  };

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <Grid container spacing={1} direction="row">
          <Grid item spacing={1}>
            シーズン：
          </Grid>
          <Grid item>
            {singlePeriod && (
              <div style={{ width: 300, marginTop: -12 }}>
                <SingleSelect
                  selected={seasonOption}
                  options={[
                    { name: "北関東シーズン", value: "cm64mqm94000br3n0esu8c0n4" },
                    { name: "秋田県北 & 県央・県南シーズン", value: "cm46zidp30003bpp6cxq101mo" }
                  ]}
                  handleChange={handleChange}
                />
              </div>
            )}
            {!singlePeriod &&
            <Autocomplete
              multiple
              id="season-autocomplete"
              options={seasonData}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                `${option.seasonName} : ${option.seasonStart} ~ ${option.seasonEnd}`
              }
              renderOption={(option, { selected }) => {
                const isFutureSeason = moment(option.seasonStart).isAfter(currentDateTime);
                const isSelected = selectedSeason.some((selected) => selected.seasonId === option.seasonId);
                return (
                  <div
                    style={{
                      // opacity: isFutureSeason ? 0.5 : 1,
                      // pointerEvents: isFutureSeason ? "none" : "auto",
                    }}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                      disabled={isFutureSeason}
                    />
                    {`${option.seasonName}: `}
                    <span
                      style={{ color: "gray", marginLeft: 16, fontSize: 13 }}
                    >
                      {`${option.seasonStart} ~ ${option.seasonEnd}`}
                    </span>
                  </div>
                );
              }}
              value={selectedSeason || []}
              onChange={handleSelectionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder=""
                  className={classes.seasonTextField}
                />
              )}
              renderTags={(selected, getTagProps) => {
                const visibleTags = selected.slice(0, 3);
                const extraCount = selected.length - visibleTags.length;
                return [
                  ...visibleTags.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.seasonName}
                      size="small"
                      className={classes.chip}
                    />
                  )),
                  extraCount > 0 && (
                    <Typography
                      key="extra"
                      variant="body2"
                      style={{ marginLeft: 8, color: "gray" }}
                    >
                      +{extraCount}
                    </Typography>
                  ),
                ];
              }}
              disableClearable
              className={classes.autocomplete}
            />
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

PeriodNtt.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  periodType: state.pictree.periodType,
  seasonData: state.pictreeNtt.seasonData,
  selectedSeason: state.pictreeNtt.selectedSeason,
  dateRangePicTreeNtt: state.page.dateRangePicTreeNtt,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTreeNtt: (param) => dispatch(setDateRangePicTreeNtt(param)),
  setDateRangePicTreeMinMax: (param) => dispatch(setDateRangePicTreeMinMax(param)),
  getSeasonDataNtt: () => dispatch(getSeasonDataNtt()),
  setSelectedSeasonNtt: (param) => dispatch(setSelectedSeasonNtt(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PeriodNtt));

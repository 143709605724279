// Icons Material can be defined here, not fontAwesome
import HomeIcon from "@material-ui/icons/Home";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import {
  ActiveUsersByGame,
  PicTreeHome,
  PicTreeSalesOverview,
  PicTreeRegistration,
  PicTreeCheckIn,
  PicTreeMap,
  PicTreeReviewSummary,
  PicTreePointExchange,
  PicTreeRewardCoin,
  PicTreeReviewAppearance,
  PicTreeMissionSummary,
  PicTreeMissionRanking,
  PicTreeReviewSummaryNtt,
  PicTreeCheckInNtt,
  PicTreeMapNtt,
} from "pages";

export default {
  items: [
    {
      path: "/game/pictree",
      name: "PicTrée TEPCO",
      type: "submenu",
      icon: CameraAltIcon,
      component: null,
      sideBar: true,
      children: [
        {
          path: "/home",
          name: "ホーム",
          type: "link",
          icon: HomeIcon,
          component: PicTreeHome,
          sideBar: true,
          divider: true,
        },
        {
          path: "/sales",
          name: "売上概要",
          type: "link",
          icon: false,
          category: "課金",
          component: PicTreeSalesOverview,
          sideBar: true,
          divider: false,
        },
        {
          path: "/point",
          name: "ポイント交換",
          type: "link",
          icon: false,
          component: PicTreePointExchange,
          sideBar: true,
          divider: false,
        },
        {
          path: "/reward-coin",
          name: "報酬コイン",
          type: "link",
          icon: false,
          component: PicTreeRewardCoin,
          sideBar: true,
          divider: false,
        },
        {
          path: "/registration",
          name: "集客",
          type: "link",
          icon: false,
          category: "GAME",
          component: PicTreeRegistration,
          sideBar: true,
          divider: false,
        },
        {
          path: "/active-users",
          name: "アクティブユーザー",
          icon: null,
          component: ActiveUsersByGame,
        },
        {
          path: "/checkin",
          name: "チェックイン",
          type: "link",
          icon: false,
          component: PicTreeCheckIn,
          sideBar: true,
          divider: false,
        },
        {
          path: "/photos",
          name: "通常モード",
          type: "submenu",
          category: "撮影",
          sideBar: true,
          children: [
            {
              path: "/overview",
              name: "概要",
              icon: null,
              component: PicTreeReviewSummary,
            },
            {
              path: "/map",
              name: "地図表示",
              icon: null,
              component: PicTreeMap,
            },
          ]
        },
        {
          path: "/mission",
          name: "ミッションモード",
          type: "submenu",
          sideBar: true,
          children: [
            {
              path: "/overview",
              name: "概要",
              icon: null,
              component: PicTreeMissionSummary,
            },
            {
              path: "/ranking",
              name: "達成ランキング",
              icon: null,
              component: PicTreeMissionRanking,
            },
          ],
        },
      ],
      divider: false,
    },
    {
      path: "/game/pictree/ntt",
      name: "PicTrée NTT",
      type: "submenu",
      icon: CameraAltIcon,
      component: null,
      sideBar: true,
      children: [
        {
          path: "/photos/overview",
          name: "概要",
          type: "link",
          icon: false,
          category: "撮影",
          component: PicTreeReviewSummaryNtt,
          sideBar: true,
          divider: true,
        },
        {
          path: "/photos/map",
          name: "地図表示",
          type: "link",
          icon: false,
          component: PicTreeMapNtt,
          sideBar: true,
          divider: false,
        },
        {
          path: "/checkin",
          name: "チェックイン",
          type: "link",
          icon: false,
          component: PicTreeCheckInNtt,
          sideBar: true,
          divider: false,
        },
      ]
    },
  ],
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Box, LinearProgress, Typography } from '@material-ui/core';
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) =>
  createStyles({
    lblPercent: {
      marginTop: 32,
      flexGrow: 1,
      textAlign: "right",
      fontSize: 13,
    },
    tooltip: {
      position: 'absolute',
      top: '8px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '5px 10px',
      borderRadius: '4px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
  })
);

const PhotoShootNtt = ({
  dateRange,
  homeDataPhotoShoot,
  selectedSeason,
}) => {
  const classes = useStyles();
  const powerPoleTotal = selectedSeason.length > 0 && selectedSeason[0]["seasonId"] === "cm64mqm94000br3n0esu8c0n4" ? 150099 : 2957;

  return (
    <Grid container spacing={1}>
      <Grid item m={12} md={8} lg={8}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="総写真数"
                  value={homeDataPhotoShoot.countPic !== undefined ? homeDataPhotoShoot.countPic  : "-"}
                  prevValue={homeDataPhotoShoot.countPicPrev !== undefined ? homeDataPhotoShoot.countPicPrev  : "-"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="総基数"
                  value={homeDataPhotoShoot.countAsset !== undefined ? homeDataPhotoShoot.countAsset  : "-"}
                  prevValue={homeDataPhotoShoot.countAssetPrev !== undefined ? homeDataPhotoShoot.countAssetPrev  : "-"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="電柱基数"
                  value={homeDataPhotoShoot.countDenchu !== undefined ? homeDataPhotoShoot.countDenchu  : "-"}
                  prevValue={homeDataPhotoShoot.countDenchuPrev !== undefined ? homeDataPhotoShoot.countDenchuPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeDenchu !== undefined ? `(重複数: ${homeDataPhotoShoot.countDupeDenchu})`  : null}
                  tip="ユニークの電柱基数"
                  valueExtra={`達成率: ${
                    powerPoleTotal !== undefined
                    ? (homeDataPhotoShoot.countDenchu/powerPoleTotal*100).toFixed(2)+'%'
                    : "--"}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="撮影者数"
                  value={homeDataPhotoShoot.countUniqueUser !== undefined ? homeDataPhotoShoot.countUniqueUser  : "-"}
                  prevValue={homeDataPhotoShoot.countUniqueUserPrev !== undefined ? homeDataPhotoShoot.countUniqueUserPrev  : "-"}
                  lastItem={true}
                  tip="ユニークの撮影者数"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}></Grid>
              <Grid item xs={12} sm={6} md={3}></Grid>
              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item m={12} md={4} lg={4}>
        {homeDataPhotoShoot.progressDenchu !== undefined && selectedSeason.length > 0 &&
        <>
        <Typography variant="body2">
          {`${selectedSeason[0]["seasonStart"].split(" ")[0]} ~ ${selectedSeason[0]["seasonEnd"].split(" ")[0]} アセット撮影達成率`}
        </Typography>
        <div className={classes.lblPercent}>
            <Box width="100%" position="relative">
              <Box className={classes.tooltip}>
                {`${Math.round(homeDataPhotoShoot.progressDenchu.taken)}
                (${((homeDataPhotoShoot.progressDenchu.taken / homeDataPhotoShoot.progressDenchu.max) * 100).toFixed(2)}%)`}
              </Box>
              <Box>
                <LinearProgress variant="determinate" value={
                  homeDataPhotoShoot.progressDenchu.taken !== undefined
                  ? (homeDataPhotoShoot.progressDenchu.taken / homeDataPhotoShoot.progressDenchu.max) * 100
                  : 0
                }
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2" color="textSecondary">0</Typography>
                <Typography variant="body2" color="textSecondary">
                  {homeDataPhotoShoot.progressDenchu.max !== undefined ? homeDataPhotoShoot.progressDenchu.max : "--"}
                </Typography>
              </Box>
            </Box>
        </div>
        </>
        }
      </Grid>
    </Grid>
  );
};

PhotoShootNtt.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRangePicTree,
  homeDataPhotoShoot: state.pictreeNtt.homeDataPhotoShoot,
  selectedSeason: state.pictreeNtt.selectedSeason,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PhotoShootNtt));

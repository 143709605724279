import React, { useState, useEffect, useRef } from "react";
import ReactMapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
} from "react-map-gl";
import { Chip } from "@material-ui/core";

const MAPBOX_TOKEN = "pk.eyJ1IjoieWFtYXNuYXgiLCJhIjoiY2prOTlub3cwMnQ5dzNsbWxpNXpxdTJoNyJ9.iPwzkcOPb0xHua5IwXCElA";

function PhotoShootMapNtt({ records, type, width, height, status, seasonId }) {
  const [mapStyle, setMapStyle] = useState("mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb");
  const [terrainEnabled, setTerrainEnabled] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const popupHovered = useRef(false);

  const calculateBounds = (records) => {
    const bounds = records.reduce(
      (acc, curr) => {
        return {
          minLng: Math.min(acc.minLng, parseFloat(curr.longitude)),
          minLat: Math.min(acc.minLat, parseFloat(curr.latitude)),
          maxLng: Math.max(acc.maxLng, parseFloat(curr.longitude)),
          maxLat: Math.max(acc.maxLat, parseFloat(curr.latitude)),
        };
      },
      {
        minLng: Infinity,
        minLat: Infinity,
        maxLng: -Infinity,
        maxLat: -Infinity,
      }
    );

    const paddingPercentage = 0.1;
    const lngPadding = (bounds.maxLng - bounds.minLng) * paddingPercentage;
    const latPadding = (bounds.maxLat - bounds.minLat) * paddingPercentage;

    return {
      minLng: bounds.minLng - lngPadding,
      minLat: bounds.minLat - latPadding,
      maxLng: bounds.maxLng + lngPadding,
      maxLat: bounds.maxLat + latPadding,
    };
  };

  const calculateCenterAndZoom = (bounds) => {
    const center = [
      (bounds.minLng + bounds.maxLng) / 2,
      (bounds.minLat + bounds.maxLat) / 2,
    ];
    const zoom = Math.min(
      Math.log2(960 / (bounds.maxLng - bounds.minLng)),
      Math.log2(480 / (bounds.maxLat - bounds.minLat))
    );
    return { center, zoom };
  };

  const boundsWithPadding = records.reduce(
    (acc, curr) => {
      return {
        minLng: Math.min(acc.minLng, parseFloat(curr.longitude)),
        minLat: Math.min(acc.minLat, parseFloat(curr.latitude)),
        maxLng: Math.max(acc.maxLng, parseFloat(curr.longitude)),
        maxLat: Math.max(acc.maxLat, parseFloat(curr.latitude)),
      };
    },
    {
      minLng: Infinity,
      minLat: Infinity,
      maxLng: -Infinity,
      maxLat: -Infinity,
    }
  );
  const paddingPercentage = 0.1;

  const lngPadding =
    (boundsWithPadding.maxLng - boundsWithPadding.minLng) * paddingPercentage;
  const latPadding =
    (boundsWithPadding.maxLat - boundsWithPadding.minLat) * paddingPercentage;
  const bounds = {
    minLng: boundsWithPadding.minLng - lngPadding,
    minLat: boundsWithPadding.minLat - latPadding,
    maxLng: boundsWithPadding.maxLng + lngPadding,
    maxLat: boundsWithPadding.maxLat + latPadding,
  };

  const center = [
    (bounds.minLng + bounds.maxLng) / 2,
    (bounds.minLat + bounds.maxLat) / 2,
  ];
  const zoom = Math.min(
    Math.log2(960 / (bounds.maxLng - bounds.minLng)),
    Math.log2(480 / (bounds.maxLat - bounds.minLat))
  );

  const [viewport, setViewport] = React.useState({
    "latitude": 36.339444,
    "longitude": 139.731667,
    "zoom": 8.178678887595819,
    bearing: terrainEnabled ? 80 : 0,
    pitch: terrainEnabled ? 80 : 0,
  });
  // akita
  // "latitude": 39.6907518,
  // "longitude": 140.3662165,
  // "zoom": 8.178678887595819,

  useEffect(() => {
    if (records.length > 0) {
      const bounds = calculateBounds(records);
      const { center, zoom } = calculateCenterAndZoom(bounds);
      setViewport({
        latitude: center[1],
        longitude: center[0],
        zoom: zoom,
        bearing: terrainEnabled ? 80 : 0,
        pitch: terrainEnabled ? 80 : 0,
      });
    } else {
      setViewport({
        "latitude": 36.339444,
        "longitude": 139.731667,
        "zoom": 8.178678887595819,
        "bearing": 0,
        "pitch": 0
      });
    }
  }, [records, terrainEnabled]);

  const toggleMapStyle = () => {
    setMapStyle((prev) =>
      prev === "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
        ? "mapbox://styles/mapbox/satellite-v9"
        : "mapbox://styles/yamasnax/clubgryio000s01p98ef99wcb"
    );
  };

  const toggleTerrain = () => {
    setTerrainEnabled(!terrainEnabled);
  };

  const geojsonData = {
    type: "FeatureCollection",
    features: records.map((record) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(record.longitude), parseFloat(record.latitude)],
      },
      properties: {
        id: record.id,
        power_asset_id: record.power_asset_id,
        prefectural_area: record.prefectural_area,
        exchange_office_name: record.exchange_office_name,
        asset_info: record.asset_info,
        photography_target: record.photography_target,
      },
    })),
  };

  const totalPoints = records.length;

  return (
    <div style={{ width: width, height: height }}>
      <ReactMapGL
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: "100%", height: "90%" }}
        mapStyle={mapStyle}
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        onMouseMove={(event) => {
          if (popupHovered.current) return;
          const feature = event.features && event.features[0];
          setHoveredFeature(feature);
        }}
        interactiveLayerIds={["unclustered-points"]}
        terrain={
          terrainEnabled ? { source: "mapbox-dem", exaggeration: 1.5 } : null
        }
      >
        <FullscreenControl />
        <NavigationControl />

        <Source
          id="clustered-data"
          type="geojson"
          data={geojsonData}
          cluster={true}  // クラスタリングを有効化
          clusterMaxZoom={14}  // クラスタリングが無効化されるズームレベル
          clusterRadius={60}  // クラスタリングの半径（ピクセル）
          generateId={true}
        >
          <Layer
            id="admin-state-province"
            type="line"
            source-layer="admin"
            filter={["==", "admin_level", 1]} // 都道府県境界
            layout={{ "line-join": "round", "line-cap": "round" }}
            paint={{
              "line-color": "#000000", // 黒
              "line-width": 4, // 太めに
            }}
          />

          <Layer
            id="clusters"
            type="circle"
            source="clustered-data"
            filter={["has", "point_count"]}
            paint={{
              "circle-color": "#3E3E3E",
              "circle-radius": [
                "step",
                ["get", "point_count"],
                32, // デフォルトサイズ
                100, 35,
                750, 45
              ],
              "circle-opacity": 0.9, // 少し透過を減らす
            }}
          />

          <Layer
            id="cluster-count-percentage"
            type="symbol"
            source="clustered-data"
            filter={["has", "point_count"]}
            layout={{
              "text-field": [
                "format",
                ["get", "point_count"], // 点の数
                { "font-scale": 1.5 },
                "\n",
                {},
                ["case",
                  ["<", ["*", ["/", ["get", "point_count"], totalPoints], 100], 1], // 1%未満なら
                  "",
                  ["concat", // 1%以上なら通常の % 表示
                    ["to-string", [
                      "round",
                      ["*", ["/", ["get", "point_count"], totalPoints], 100] // 計算: (点の数 / 全体数) * 100
                    ]],
                    "%"
                  ]
                ],
                { "font-scale": 1.2 }
              ],
              "text-size": 16,
              "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
              "text-anchor": "center",
            }}
            paint={{
              "text-color": "#ffffff",
            }}
          />

          {/* クラスタを展開したときの個別ポイント表示 */}
          <Layer
            id="unclustered-points"
            type="circle"
            source="clustered-data"
            filter={["!", ["has", "point_count"]]}
            paint={{
              "circle-color": "#ff0000",
              "circle-radius": 6,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#ffffff",
            }}
          />
        </Source>

        {/* ホバー時のツールチップ */}
        {hoveredFeature && (
          <Popup
            longitude={hoveredFeature.geometry.coordinates[0]}
            latitude={hoveredFeature.geometry.coordinates[1]}
            closeButton={false}
            closeOnClick={false}
            anchor="top"
            style={{
              backgroundColor: "white",
              padding: "0px",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            }}
            onMouseEnter={() => {
              popupHovered.current = true; // 🔥 Popupの上では `hoveredFeature` を変更しない
            }}
            onMouseLeave={() => {
              popupHovered.current = false;
              setTimeout(() => {
                if (!popupHovered.current) {
                  setHoveredFeature(null);
                }
              }, 300);
            }}
          >
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
              📍 {hoveredFeature.properties.prefectural_area}
            </div>
            <div>ID: {hoveredFeature.properties.id !== "null" ? hoveredFeature.properties.id : "--"}</div>
            <div>局名: {hoveredFeature.properties.exchange_office_name}</div>
            <div>電柱情報: {hoveredFeature.properties.asset_info}</div>
            <div>撮影対象: {hoveredFeature.properties.photography_target}</div>

            {/* Google Street View ボタン */}
            <button
              style={{
                marginTop: "10px",
                padding: "5px 10px",
                backgroundColor: "#4285F4",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                const lat = hoveredFeature.geometry.coordinates[1];
                const lng = hoveredFeature.geometry.coordinates[0];
                const googleStreetViewUrl = `https://www.google.com/maps?q=&layer=c&cbll=${lat},${lng}`;
                window.open(googleStreetViewUrl, "_blank");
              }}
              onMouseEnter={() => (popupHovered.current = true)} // ボタンの上でも消えない
              onMouseLeave={() => {
                popupHovered.current = false;
                setTimeout(() => {
                  if (!popupHovered.current) {
                    setHoveredFeature(null);
                  }
                }, 300);
              }}
            >
              🏠 Google Street View で見る
            </button>
          </Popup>
        )}

        <div style={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}>
          <Chip
            size="small"
            onClick={toggleMapStyle}
            label={
              mapStyle.includes("yamasnax") ? "航空写真表示" : "デフォルト表示"
            }
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
          <Chip
            size="small"
            onClick={toggleTerrain}
            label={terrainEnabled ? "2D表示" : "3D表示"}
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
        </div>

        {terrainEnabled && (
          <>
            <Source
              id="mapbox-dem"
              type="raster-dem"
              url="mapbox://mapbox.mapbox-terrain-dem-v1"
              tileSize={512}
              maxzoom={14}
            />
            <Layer
              id="terrain-data"
              type="hillshade"
              source="mapbox-dem"
              paint={{
                "hillshade-exaggeration": 0.5,
              }}
            />
          </>
        )}
      </ReactMapGL>
    </div>
  );
}

export default PhotoShootMapNtt;

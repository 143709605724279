import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getHomePictreeCheckInNtt,
  getPictreeDataCheckInTrendNtt,
  setPictreeChartSize,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import CheckInNtt from "components/PicTree/Home/CheckInNtt";
import ChartConfig from "utils/ChartConfig";
import LineChartPictreeCheckIn from "components/Charts/LineChartPictreeCheckIn";
import PicTreeTableCheckIn from "components/Table/PicTreeTableCheckIn";
import SingleSelect from "components/Select/SingleSelect";
import CalendarRangePicTreeNtt from "components/Calendar/CalendarRangePicTreeNtt";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeCheckInNtt = ({
  setCurrentPage,
  pageTopLoader,
  getHomePictreeCheckInNtt,
  getPictreeDataCheckInTrendNtt,
  checkInTrendData,
  setPictreeChartSize,
  pictreeChartSize,
  dateRangePicTreeNtt,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [chartSize, setChartSize] = useState({ name: "日別", value: "day" });
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  useEffect(() => {
    if (
      dateRangePicTreeNtt[0] !== null &&
      dateRangePicTreeNtt[1] !== null &&
      initialDataCall
    ) {
      getHomePictreeCheckInNtt();
      getPictreeDataCheckInTrendNtt("day");
      setInitialDataCall(false);
    }
  }, [dateRangePicTreeNtt]);

  const handleChange = (event) => {
    setChartSize(event.target.value);
    setPictreeChartSize(event.target.value);
    getPictreeDataCheckInTrendNtt(event.target.value);
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée NTT</Typography>
              <Typography variant="body1">チェックイン</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <CalendarRangePicTreeNtt mDate={"2024/12/7"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <CheckInNtt />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 48 }}>
            <SingleSelect
              selected={chartSize}
              options={[
                { name: "時間別", value: "hour" },
                { name: "日別", value: "day" },
              ]}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>

        <>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : chartState ? (
                checkInTrendData.length > 0 ? (
                  <LineChartPictreeCheckIn
                    legend={ChartConfig.legendPicTreeTrend}
                    lblSaveImage={""}
                    xAisData={checkInTrendData[0]["date"]}
                    seriesData={checkInTrendData}
                    chartType={"line"}
                  />
                ) : (
                  <div className={classes.selectGameTxt}>
                    <Chip
                      label="No Data"
                      style={{
                        color: V.textColor,
                        backgroundColor: V.placeholderColor,
                      }}
                    />
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              <PicTreeTableCheckIn game="PicTree" />
            </Grid>
          </Grid>
        </>
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeCheckInNtt.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  checkInTrendData: state.pictree.checkInTrend,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartSize,
  dateRangePicTreeNtt: state.page.dateRangePicTreeNtt,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreeCheckInNtt: () => dispatch(getHomePictreeCheckInNtt()),
  getPictreeDataCheckInTrendNtt: (param) => dispatch(getPictreeDataCheckInTrendNtt(param)),
  setPictreeChartSize: (param) => dispatch(setPictreeChartSize(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeCheckInNtt));

import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getHomePictreeCheckIn,
  getPictreeDataCheckInTrend,
  getPictreeDataCheckInTable,
  setPictreeView,
  getPictreeDataCheckInMap,
  setPictreeChartSize,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import CheckIn from "components/PicTree/Home/CheckIn";
import ChartConfig from "utils/ChartConfig";
import LineChartPictreeCheckIn from "components/Charts/LineChartPictreeCheckIn";
import PicTreeTableCheckIn from "components/Table/PicTreeTableCheckIn";
import PhotoShootMap from "components/PicTree/Game/Map/PhotoShootMap";
import SingleSelect from "components/Select/SingleSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeCheckIn = ({
  setCurrentPage,
  pageTopLoader,
  dateRangePicTree,
  getHomePictreeCheckIn,
  getPictreeDataCheckInTrend,
  getPictreeDataCheckInMap,
  getPictreeDataCheckInTable,
  checkInTrendData,
  dataCheckInMap,
  setPictreeView,
  pictreeView,
  setPictreeChartSize,
  pictreeChartSize,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [chartSize, setChartSize] = useState({ name: "日別", value: "day" });
  const [view, setView] = useState("checkin/chart");
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setPictreeView("checkin/chart");
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getHomePictreeCheckIn();
      getPictreeDataCheckInTrend("day");
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  useEffect(() => {
    if (pictreeView === "checkin/chart") {
      getPictreeDataCheckInTrend(pictreeChartSize);
    } else if (pictreeView === "checkin/table") {
      getPictreeDataCheckInTable();
    } else if (pictreeView === "checkin/map") {
      getPictreeDataCheckInMap();
    }
  }, [pictreeView]);

  const handleView = (view) => {
    setPictreeView(view);
  };

  const handleChange = (event) => {
    setChartSize(event.target.value);
    setPictreeChartSize(event.target.value)
    getPictreeDataCheckInTrend(event.target.value);
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée TEPCO</Typography>
              <Typography variant="body1">Game</Typography>
              <Typography variant="body1">チェックイン</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <CheckIn />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 48, }}>
            {pictreeView === "checkin/chart" &&
              <SingleSelect
                selected={chartSize}
                options={[
                  { name: "時間別", value: "hour" },
                  { name: "日別", value: "day" },
                ]}
                handleChange={handleChange}
              />
            }
          </Grid>
        </Grid>

        {pictreeView === "checkin/chart" &&
          <>
            <Grid container spacing={1} className={classes.gridSection}>
              <Grid item xs={12} md={12} lg={12}>
                {pageTopLoader ? (
                  <div className={classes.loaderBox}>
                    <img
                      className={classes.loaderBarImg}
                      src={`/static/images/loader-bar.gif`}
                    />
                  </div>
                ) : chartState ? (
                  checkInTrendData.length > 0 ? (
                    <LineChartPictreeCheckIn
                      legend={ChartConfig.legendPicTreeTrend}
                      lblSaveImage={""}
                      xAisData={checkInTrendData[0]["date"]}
                      seriesData={checkInTrendData}
                      chartType={"line"}
                    />
                  ) : (
                    <div className={classes.selectGameTxt}>
                      <Chip
                        label="No Data"
                        style={{
                          color: V.textColor,
                          backgroundColor: V.placeholderColor,
                        }}
                      />
                    </div>
                  )
                ) : null}
              </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection}>
              <Grid item xs={12} md={12} lg={12}>
                <PicTreeTableCheckIn game="PicTree" />
              </Grid>
            </Grid>
          </>
        }
        {pictreeView === "checkin/map" &&
         dataCheckInMap.length > 0 &&
          <PhotoShootMap records={dataCheckInMap} type="checkin" width="100%" height="70vh" />
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeCheckIn.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRangePicTree: state.page.dateRangePicTree,
  checkInTrendData: state.pictree.checkInTrend,
  dataCheckInMap: state.pictree.dataCheckInMap,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartSize,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreeCheckIn: () => dispatch(getHomePictreeCheckIn()),
  getPictreeDataCheckInTrend: (param) => dispatch(getPictreeDataCheckInTrend(param)),
  getPictreeDataCheckInTable: () => dispatch(getPictreeDataCheckInTable()),
  getPictreeDataCheckInMap: () => dispatch(getPictreeDataCheckInMap()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
  setPictreeChartSize: (param) => dispatch(setPictreeChartSize(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeCheckIn));
